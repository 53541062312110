import { tv } from "tailwind-variants";

export const fontFamily = tv(
  {
    variants: {
      fontFamily: {
        sans: "font-sans",
        serif: "font-serif",
      },
    },
  },
  { responsiveVariants: true }
);
