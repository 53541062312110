import { VariantProps, tv } from 'tailwind-variants';

export const textColor = tv(
  {
    variants: {
      color: {
        weak: 'text-text-secondary',
        strong: 'text-text-primary',
      },
      hoverColor: {
        weak: 'hover:text-text-secondary',
        strong: 'hover:text-text-primary',
      },
    },
  },
  { responsiveVariants: true },
);

export type TextColors = VariantProps<typeof textColor>;
