import { tv } from "tailwind-variants";

export const useTextLeading = tv(
  {
    variants: {
      leading: {
        none: "leading-none",
        normal: "leading-normal",
      },
    },
  },
  {
    responsiveVariants: true,
  }
);
