import { tv } from 'tailwind-variants';

export const useZIndex = tv(
  {
    variants: {
      zIndex: {
        navigation: 'z-10',
        mobileDrawer: 'z-[5]',
        modal: 'z-20',
        sheet: 'z-20',
      },
    },
  },
  { responsiveVariants: true }
);
