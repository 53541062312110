import { tv } from "tailwind-variants";

export const useAspectRatio = tv(
  {
    variants: {
      aspectRatio: {
        "1/1": "aspect-[1/1]",
        "16/9": "aspect-[16/9]",
        "3/4": "aspect-[3/4]",
        "4/3": "aspect-[4/3]",
        "5/6": "aspect-[5/6]",
      },
    },
  },
  { responsiveVariants: true }
);
