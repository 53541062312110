import { tv } from 'tailwind-variants';

export const useBorderColor = tv(
  {
    variants: {
      borderColor: {
        muted: 'border-border-weak',
        error: 'border-[var(--error-foreground)]',
        black: 'border-black',
        charcoal: 'border-charcoal',
      },
    },
  },
  { responsiveVariants: true }
);
