import { tv } from "tailwind-variants";

export const usePadding = tv(
  {
    variants: {
      padding: {
        0: "p-0",
        1: "p-1",
        2: "p-2",
        3: "p-3",
        4: "p-4",
        5: "p-5",
        6: "p-6",
        7: "p-7",
        8: "p-8",
        9: "p-9",
        10: "p-10",
        11: "p-11",
        12: "p-12",
        13: "p-13",
        14: "p-14",
        15: "p-15",
        16: "p-16",
        18: "p-18",
        20: "p-20",
        gutter: "p-[var(--gutter)]",
      },
      paddingTop: {
        0: "pt-0",
        1: "pt-1",
        2: "pt-2",
        3: "pt-3",
        4: "pt-4",
        5: "pt-5",
        6: "pt-6",
        7: "pt-7",
        8: "pt-8",
        9: "pt-9",
        10: "pt-10",
        11: "pt-11",
        12: "pt-12",
        13: "pt-13",
        14: "pt-14",
        15: "pt-15",
        16: "pt-16",
        18: "pt-18",
        20: "pt-20",
        gutter: "pt-[var(--gutter)]",
      },
      paddingRight: {
        0: "pr-0",
        1: "pr-1",
        2: "pr-2",
        3: "pr-3",
        4: "pr-4",
        5: "pr-5",
        6: "pr-6",
        7: "pr-7",
        8: "pr-8",
        9: "pr-9",
        10: "pr-10",
        11: "pr-11",
        12: "pr-12",
        13: "pr-13",
        14: "pr-14",
        15: "pr-15",
        16: "pr-16",
        18: "pr-18",
        20: "pr-20",
        gutter: "pr-[var(--gutter)]",
      },
      paddingBottom: {
        0: "pb-0",
        1: "pb-1",
        2: "pb-2",
        3: "pb-3",
        4: "pb-4",
        5: "pb-5",
        6: "pb-6",
        7: "pb-7",
        8: "pb-8",
        9: "pb-9",
        10: "pb-10",
        11: "pb-11",
        12: "pb-12",
        13: "pb-13",
        14: "pb-14",
        15: "pb-15",
        16: "pb-16",
        18: "pb-18",
        20: "pb-20",
        gutter: "pb-[var(--gutter)]",
      },
      paddingLeft: {
        0: "pl-0",
        1: "pl-1",
        2: "pl-2",
        3: "pl-3",
        4: "pl-4",
        5: "pl-5",
        6: "pl-6",
        7: "pl-7",
        8: "pl-8",
        9: "pl-9",
        10: "pl-10",
        11: "pl-11",
        12: "pl-12",
        13: "pl-13",
        14: "pl-14",
        15: "pl-15",
        16: "pl-16",
        18: "pl-18",
        20: "pl-20",
        gutter: "pl-[var(--gutter)]",
      },
      paddingY: {
        0: "py-0",
        1: "py-1",
        2: "py-2",
        3: "py-3",
        4: "py-4",
        5: "py-5",
        6: "py-6",
        7: "py-7",
        8: "py-8",
        9: "py-9",
        10: "py-10",
        11: "py-11",
        12: "py-12",
        13: "py-13",
        14: "py-14",
        15: "py-15",
        16: "py-16",
        18: "py-18",
        20: "py-20",
        gutter: "py-[var(--gutter)]",
      },
      paddingX: {
        0: "px-0",
        1: "px-1",
        2: "px-2",
        3: "px-3",
        4: "px-4",
        5: "px-5",
        6: "px-6",
        7: "px-7",
        8: "px-8",
        9: "px-9",
        10: "px-10",
        11: "px-11",
        12: "px-12",
        13: "px-13",
        14: "px-14",
        15: "px-15",
        16: "px-16",
        18: "px-18",
        20: "px-20",
        gutter: "px-[var(--gutter)]",
      },
    },
  },
  { responsiveVariants: true }
);
