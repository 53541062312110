import { tv } from "tailwind-variants";

export const useBoxShadow = tv(
  {
    variants: {
      boxShadow: {
        sm: "shadow-sm",
        md: "shadow-md",
        lg: "shadow-lg",
      },
    },
  },
  { responsiveVariants: true }
);
