import clsx from 'clsx';
import { ElementType, createElement, forwardRef } from 'react';
import { VariantProps, tv } from 'tailwind-variants';
import { useTextAlign } from '@/src/packages/theme/useTextAlign';
import { useHeight } from '@/src/packages/theme/useHeight';
import {
  useBorder,
  useBorderRadius,
  useFontWeight,
  useMargin,
  usePadding,
  textSizes,
  useTransitionDuration,
  useWidth,
  useZIndex,
  textColor,
  fontFamily,
} from '@/src/packages/theme';
import { usePosition } from '@/src/packages/theme/usePosition';
import { useAspectRatio } from '@/src/packages/theme/useAspectRatio';
import { useBackgroundColor } from '@/src/packages/theme/useBackgroundColor';
import { useBorderColor } from '@/src/packages/theme/useBorderColor';
import { useColSpan } from '@/src/packages/theme/useColSpan';
import { useGridGap } from '@/src/packages/theme/useGridGap';
import { useBoxShadow } from '@/src/packages/theme/useShadow';
import { Slot } from '@radix-ui/react-slot';

const box = tv(
  {
    variants: {
      alignItems: {
        center: 'items-center',
      },
      justifyContent: {
        center: 'justify-center',
      },
      display: {
        flex: 'flex',
        inlineFlex: 'inline-flex',
        inlineBlock: 'inline-block',
        grid: 'grid',
        block: 'block',
        none: 'hidden',
      },
    },
  },
  { responsiveVariants: true },
);

type BoxVariants = VariantProps<typeof box> &
  VariantProps<typeof useAspectRatio> &
  VariantProps<typeof useGridGap> &
  VariantProps<typeof useBorderColor> &
  VariantProps<typeof useBorder> &
  VariantProps<typeof useBorderRadius> &
  VariantProps<typeof useBoxShadow> &
  VariantProps<typeof useColSpan> &
  VariantProps<typeof textColor> &
  VariantProps<typeof textSizes> &
  VariantProps<typeof useTextAlign> &
  VariantProps<typeof useFontWeight> &
  VariantProps<typeof fontFamily> &
  VariantProps<typeof usePosition> &
  VariantProps<typeof usePadding> &
  VariantProps<typeof useHeight> &
  VariantProps<typeof useWidth> &
  VariantProps<typeof useZIndex> &
  VariantProps<typeof useTransitionDuration> &
  VariantProps<typeof useBackgroundColor> &
  VariantProps<typeof useMargin>;

export type As = React.ElementType;

/**
 * Extract the props of a React element or component
 */
export type PropsOf<T extends As> = React.ComponentPropsWithoutRef<T> & {
  as?: As;
};

export type IBoxProps<T = HTMLElement> = React.PropsWithChildren &
  Pick<
    React.AllHTMLAttributes<T>,
    | 'id'
    | 'name'
    | 'autoComplete'
    | 'placeholder'
    | 'autoFocus'
    | 'disabled'
    | 'target'
    | 'className'
    | 'href'
    | 'style'
    | 'onClick'
    | 'onChange'
    | 'onBlur'
    | 'onFocus'
    | 'onKeyDown'
    | 'onKeyUp'
    | 'onPaste'
    | 'value'
    | 'defaultValue'
    | 'rel'
    | 'onMouseEnter'
    | 'onMouseLeave'
    | 'tabIndex'
    | 'type'
  > &
  BoxVariants & {
    as?: ElementType;
    asChild?: boolean;
    testId?: string;
  };

export const Box = forwardRef<HTMLElement, IBoxProps>((props, ref) => {
  const {
    as = 'div',
    asChild,
    testId,
    fontWeight,
    className,
    colSpan,
    borderColor,
    borderDirection,
    display,
    backgroundColor,
    hoverBackgroundColor,
    aspectRatio,
    width,
    height,
    justifyContent,
    alignItems,
    color,
    textSize,
    textAlign,
    hoverColor,
    boxShadow,
    borderRadius,
    borderWidth,
    margin,
    marginRight,
    marginLeft,
    marginTop,
    marginBottom,
    marginX,
    marginY,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    paddingX,
    paddingY,
    position,
    zIndex,
    gap,
    fontFamily: fontFamilyProp,
    transitionDuration,
    ...rest
  } = props;

  const Comp = asChild ? Slot : as;

  return createElement(Comp, {
    ...(testId && { 'data-testid': testId }),
    ref,
    className: clsx(
      box({
        display,
        alignItems,
        justifyContent,
      }),
      useAspectRatio({ aspectRatio }),
      useBackgroundColor({ backgroundColor, hoverBackgroundColor }),
      useBorder({ borderDirection, borderWidth }),
      useBorderColor({ borderColor }),
      useColSpan({ colSpan }),
      useWidth({ width }),
      useHeight({ height }),
      textSizes({ textSize }),
      textColor({ color, hoverColor }),
      useTextAlign({ textAlign }),
      useFontWeight({ fontWeight }),
      useGridGap({ gap }),
      usePadding({
        padding,
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft,
        paddingX,
        paddingY,
      }),
      useMargin({
        margin,
        marginX,
        marginY,
        marginBottom,
        marginRight,
        marginLeft,
        marginTop,
      }),
      useBorderRadius({ borderRadius }),
      useBoxShadow({ boxShadow }),
      useZIndex({ zIndex }),
      usePosition({ position }),
      fontFamily({ fontFamily: fontFamilyProp }),
      useTransitionDuration({ transitionDuration }),
      className,
    ),
    ...rest,
  });
});

Box.displayName = 'Box';
