'use client';
import { Slot } from '@radix-ui/react-slot';
import { tv, VariantProps } from 'tailwind-variants';
import clsx from 'clsx';
import { useHeight } from '@/src/packages/theme/useHeight';
import { usePadding } from '@/src/packages/theme/usePadding';
import { Loader } from '@/src/packages/components/ui/Loader';
import { cn } from '@/src/packages/utils/cn';
import { forwardRef } from 'react';

export interface IButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'>,
    VariantProps<typeof useButton> {
  asChild?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const useButton = tv(
  {
    base: 'leading-none border transition-all duration-200 disabled:opacity-50 disabled:pointer-events-none inline-flex items-center justify-center',
    variants: {
      variant: {
        solid: '',
        outline: '',
      },
      color: {
        black: '',
        white: '',
      },
      size: {
        sm: cn('text-sm px-5', useHeight({ height: 'formControlSmall' })),
        default: cn(
          'text-sm',
          usePadding({ paddingX: 6 }),
          useHeight({ height: 'formControlDefault' })
        ),
        lg: cn(
          'text-base',
          useHeight({ height: 'formControlLarge' }),
          usePadding({ paddingX: 10 })
        ),
      },
      isBlock: { true: 'w-full' },
    },
    compoundVariants: [
      {
        variant: 'solid',
        color: 'black',
        class: 'bg-gray-950 text-white border-gray-950 hover:bg-gray-800',
      },
      {
        variant: 'outline',
        color: 'black',
        class: 'border-gray-300 hover:border-black',
      },
      {
        variant: 'solid',
        color: 'white',
        class:
          'border-white text-black bg-white hover:bg-white/80 hover:border-white/80',
      },
      {
        variant: 'outline',
        color: 'white',
        class: 'border-white text-white hover:border-white/50',
      },
    ],
  },
  { responsiveVariants: true }
);

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      asChild,
      children,
      isLoading = false,
      type = 'button',
      isDisabled,
      className,
      isBlock,
      color = 'black',
      size = 'default',
      variant = 'outline',
      ...rest
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        ref={ref}
        type={type}
        className={clsx(
          useButton({
            size,
            color,
            isBlock,
            variant,
          }),
          className
        )}
        disabled={isLoading || isDisabled}
        {...rest}
      >
        {isLoading ? (
          <div
            className="flex items-center justify-center h-full"
            style={{ minWidth: 30 }}
          >
            <Loader />
          </div>
        ) : (
          children
        )}
      </Comp>
    );
  }
);

Button.displayName = 'Button';
