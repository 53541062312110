import { tv } from "tailwind-variants";

export const useBorder = tv(
  {
    variants: {
      borderWidth: {
        thin: "",
      },
      borderDirection: {
        top: "",
        bottom: "",
        left: "",
        right: "",
        all: "",
      },
    },
    compoundVariants: [
      { borderDirection: "all", borderWidth: "thin", class: "border" },
      { borderDirection: "top", borderWidth: "thin", class: "border-t" },
      { borderDirection: "right", borderWidth: "thin", class: "border-r" },
      { borderDirection: "bottom", borderWidth: "thin", class: "border-b" },
      { borderDirection: "left", borderWidth: "thin", class: "border-l" },
    ],
  },
  { responsiveVariants: true }
);
