import { VariantProps, tv } from "tailwind-variants";

export const textSizes = tv(
  {
    variants: {
      textSize: {
        "lead": "text-xl",
        xs: "text-xs",
        sm: "text-sm",
        base: "text-base",
        lg: "text-lg",
        xl: "text-xl",
        "2xl": "text-2xl",
        "3xl": "text-3xl",
        "4xl": "text-4xl",
        "5xl": "text-5xl",
        "6xl": "text-6xl",
      },
    },
  },
  { responsiveVariants: true }
);

export type TextSizes = VariantProps<typeof textSizes>;
