import { tv } from "tailwind-variants";

export const useWidth = tv(
  {
    variants: {
      width: {
        full: "w-full",
        0: "w-0",
        1: "w-1",
        2: "w-2",
        3: "w-3",
        4: "w-4",
        5: "w-5",
        6: "w-6",
        7: "w-7",
        8: "w-8",
        9: "w-9",
        10: "w-10",
      },
    },
  },
  { responsiveVariants: true }
);
