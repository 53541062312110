import { tv } from "tailwind-variants";

export const usePosition = tv(
  {
    variants: {
      position: {
        fixed: "fixed",
        relative: "relative",
        absolute: "absolute",
        sticky: "sticky",
        static: "static",
      },
    },
  },
  { responsiveVariants: true }
);
