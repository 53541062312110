import { tv } from "tailwind-variants";

export const useTextAlign = tv({
  variants: {
    textAlign: {
      left: "text-left",
      center: "text-center",
      right: "text-right",
    },
  },
});
