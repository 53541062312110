import { tv } from "tailwind-variants";

export const useMargin = tv(
  {
    variants: {
      margin: {
        0: "m-0",
      },
      marginTop: {
        gutter: "mt-[var(--gutter)]",
        0: "mt-0",
        0.5: "mt-0.5",
        1: "mt-1",
        1.5: "mt-1.5",
        2: "mt-2",
        2.5: "mt-2.5",
        3: "mt-3",
        4: "mt-4",
        5: "mt-5",
        6: "mt-6",
        7: "mt-7",
        8: "mt-8",
        9: "mt-9",
        10: "mt-10",
        11: "mt-11",
        12: "mt-12",
        13: "mt-13",
        14: "mt-14",
        15: "mt-15",
        16: "mt-16",
        18: "mt-18",
        20: "mt-20",
      },
      marginRight: {
        gutter: "mr-[var(--gutter)]",
        0: "mr-0",
        0.5: "mr-0.5",
        1: "mr-1",
        1.5: "mr-1.5",
        2: "mr-2",
        2.5: "mr-2.5",
        3: "mr-3",
        4: "mr-4",
        5: "mr-5",
        6: "mr-6",
        7: "mr-7",
        8: "mr-8",
        9: "mr-9",
        10: "mr-10",
        11: "mr-11",
        12: "mr-12",
        13: "mr-13",
        14: "mr-14",
        15: "mr-15",
        16: "mr-16",
        18: "mr-18",
        20: "mr-20",
      },
      marginBottom: {
        gutter: "mb-[var(--gutter)]",
        0: "mb-0",
        0.5: "mb-0.5",
        1: "mb-1",
        1.5: "mb-1.5",
        2: "mb-2",
        2.5: "mb-2.5",
        3: "mb-3",
        4: "mb-4",
        5: "mb-5",
        6: "mb-6",
        7: "mb-7",
        8: "mb-8",
        9: "mb-9",
        10: "mb-10",
        11: "mb-11",
        12: "mb-12",
        13: "mb-13",
        14: "mb-14",
        15: "mb-15",
        16: "mb-16",
        18: "mb-18",
        20: "mb-20",
      },
      marginLeft: {
        gutter: "ml-[var(--gutter)]",
        0: "ml-0",
        0.5: "ml-0.5",
        1: "ml-1",
        1.5: "ml-1.5",
        2: "ml-2",
        2.5: "ml-2.5",
        3: "ml-3",
        4: "ml-4",
        5: "ml-5",
        6: "ml-6",
        7: "ml-7",
        8: "ml-8",
        9: "ml-9",
        10: "ml-10",
        11: "ml-11",
        12: "ml-12",
        13: "ml-13",
        14: "ml-14",
        15: "ml-15",
        16: "ml-16",
        18: "ml-18",
        20: "ml-20",
      },
      marginY: {
        gutter: "my-[var(--gutter)]",
        0: "my-0",
        1: "my-1",
        2: "my-2",
        3: "my-3",
        4: "my-4",
        5: "my-5",
        6: "my-6",
        7: "my-7",
        8: "my-8",
        9: "my-9",
        10: "my-10",
        11: "my-11",
        12: "my-12",
        13: "my-13",
        14: "my-14",
        15: "my-15",
        16: "my-16",
        18: "my-18",
        20: "my-20",
      },
      marginX: {
        gutter: "mx-[var(--gutter)]",
        0: "mx-0",
        1: "mx-1",
        2: "mx-2",
        3: "mx-3",
        4: "mx-4",
        5: "mx-5",
        6: "mx-6",
        7: "mx-7",
        8: "mx-8",
        9: "mx-9",
        10: "mx-10",
        11: "mx-11",
        12: "mx-12",
        13: "mx-13",
        14: "mx-14",
        15: "mx-15",
        16: "mx-16",
        18: "mx-18",
        20: "mx-20",
      },
    },
  },
  { responsiveVariants: true }
);
