import { useFontWeight } from '@/src/packages/theme/useFontWeight';
import { useTextAlign } from '@/src/packages/theme/useTextAlign';
import { tv, VariantProps } from 'tailwind-variants';
import { textSizes } from '@/src/packages/theme/useTextSize';
import { Box, IBoxProps } from '../Box';
import { textColor } from '@/src/packages/theme';
import { cn } from '@/src/packages/utils/cn';

export const useHeading = tv({
  variants: {
    size: {
      display: textSizes({ textSize: { initial: '3xl', lg: '4xl' } }),
      h1: textSizes({ textSize: '2xl' }),
      h2: textSizes({ textSize: 'lg' }),
      h3: textSizes({ textSize: 'base' }),
      h4: textSizes({ textSize: 'sm' }),
    },
  },
});

type HeadingVariants = VariantProps<typeof useHeading> &
  VariantProps<typeof useTextAlign> &
  VariantProps<typeof useFontWeight> &
  VariantProps<typeof textColor>;

export type IHeadingProps = {
  as: 'h1' | 'h2' | 'h3' | 'h4';
} & Omit<IBoxProps, 'as' | 'size' | 'textSize'> &
  HeadingVariants;

export const Heading = (props: IHeadingProps) => {
  const { as, className, size, ...rest } = props;

  return (
    <Box
      as={as}
      className={cn('leading-tight', useHeading({ size }), className)}
      {...rest}
    />
  );
};
