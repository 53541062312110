import { getValidChildren } from '@bitmap/utils/utils/getValidChildren';
import clsx from 'clsx';
import { cloneElement, Fragment, ReactElement } from 'react';
import { VariantProps, tv } from 'tailwind-variants';
import { Box, IBoxProps } from '../Box';
import { useMargin } from '@/src/packages/theme';

const stack = tv(
  {
    variants: {
      display: {
        block: 'flex',
        inline: 'inline-flex',
      },
      alignItems: {
        center: 'items-center',
      },
      direction: {
        vertical: 'flex-col',
        horizontal: 'flex-row',
      },
      spaceY: {
        0: 'mb-0',
        1: 'mb-1',
        2: 'mb-2',
        3: 'mb-3',
        4: 'mb-4',
        5: 'mb-5',
        6: 'mb-6',
        7: 'mb-7',
        8: 'mb-8',
        9: 'mb-9',
        10: 'mb-10',
        11: 'mb-11',
        12: 'mb-12',
        13: 'mb-13',
        14: 'mb-14',
        15: 'mb-15',
        16: 'mb-16',
        18: 'mb-18',
        20: 'mb-20',
      },
      spaceX: {
        0: 'mr-0',
        1: 'mr-1',
        2: 'mr-2',
        3: 'mr-3',
        4: 'mr-4',
        5: 'mr-5',
        6: 'mr-6',
        7: 'mr-7',
        8: 'mr-8',
        9: 'mr-9',
        10: 'mr-10',
        11: 'mr-11',
        12: 'mr-12',
        13: 'mr-13',
        14: 'mr-14',
        15: 'mr-15',
        16: 'mr-16',
        18: 'mr-18',
        20: 'mr-20',
      },
    },
  },
  { responsiveVariants: true }
);

type StackVariants = VariantProps<typeof stack>;

type IStackProps = Omit<IBoxProps, 'display'> &
  StackVariants & {
    childClassName?: string;
    divider?: ReactElement;
  };

export const Stack = (props: IStackProps) => {
  const {
    as = 'div',
    children,
    spaceX,
    spaceY,
    display = 'block',
    direction = 'vertical',
    alignItems,
    className,
    childClassName,
    divider,
    ...rest
  } = props;

  const validChildren = getValidChildren(children);

  const hasDivider = !!divider;

  const clones = validChildren.map((item, index) => {
    const isLast = index === validChildren.length - 1;

    const wrappedChild = (
      <Box
        as="div"
        className={clsx(
          !hasDivider && stack({ spaceX: isLast ? 0 : spaceX }),
          !hasDivider && stack({ spaceY: isLast ? 0 : spaceY }),
          childClassName
        )}
        key={index}
      >
        {item}
      </Box>
    );

    const _child = wrappedChild;

    if (!hasDivider) return wrappedChild;

    const clonedDivider = cloneElement(divider, {
      className: clsx(
        divider.props.className,
        useMargin({ marginX: spaceX }),
        useMargin({ marginY: spaceY })
      ),
    });

    const _divider = isLast ? null : clonedDivider;

    return (
      <Fragment key={index}>
        {_child}
        {_divider}
      </Fragment>
    );
  });

  return (
    <Box
      as={as}
      className={clsx(stack({ direction, display, alignItems }), className)}
      {...rest}
    >
      {clones}
    </Box>
  );
};
