import { tv } from 'tailwind-variants';

export const useBorderRadius = tv(
  {
    variants: {
      borderRadius: {
        default: 'rounded-[var(--border-radius-default)]',
      },
    },
  },
  { responsiveVariants: true }
);
