import { tv } from "tailwind-variants";

export const useFontWeight = tv(
  {
    variants: {
      fontWeight: {
        normal: "font-normal",
        medium: "font-medium",
        semibold: "font-semibold",
        bold: "font-bold",
      },
    },
  },
  {
    responsiveVariants: true,
  }
);
