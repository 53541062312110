import { tv } from 'tailwind-variants';

export const useBackgroundColor = tv(
  {
    variants: {
      backgroundColor: {
        white: 'bg-white',
        page: 'bg-background-page',
        panel: 'bg-background-panel',
        dialogue: 'bg-background-dialogue',
        charcoal: 'bg-charcoal',
        cream: 'bg-cream',
        creamHover: 'bg-[var(--color-cream-hover)]',
        bottleGreen: 'bg-[var(--color-bottle-green)]',
        inputBackgroundDefault: 'bg-input-background-default',
        inputBackgroundHover: 'bg-input-background-hover',
      },
      hoverBackgroundColor: {
        creamHover: 'hover:bg-[var(--color-cream-hover)]',
        inputBackgroundHover: 'hover:bg-input-background-hover',
      },
    },
  },
  { responsiveVariants: true }
);
